<template>
  <div id="app">
    <Navigation :project_name="project_name" v-if="has_admin_role()" />
    <!-- <router-view class="container-fluid" :project_name="project_name" /> -->
    <router-view :project_name="project_name" />
  </div>
</template>

<script>
  import Navigation from '@/components/Navigation.vue';

  export default {
    components: {
      Navigation,
    },
    data() {
      return {
        project_name: 'AdminFytouklaki',
      };
    },
    created() {
      let app_name = process.env.VUE_APP_NAME;
      let app_version = process.env.VUE_APP_VERSION;
      let app_git_commit = process.env.VUE_APP_GIT_COMMIT;
      console.log(app_name + '@' + app_version + '@' + app_git_commit);
    },
    methods: {
      has_admin_role: function () {
        var self = this;

        if (self.$store.state.user != null && self.$store.state.user_roles.includes('admin')) {
          return true;
        }

        return false;
      },
    },
  };
</script>

<style>
  /* #app {
    padding-left: 200px;
}
#app.collapsed {
    padding-left: 50px;
} */

  /* body {
  padding-top: 60px;
} */

  /* this is for fixed- navbar */
  body {
    min-height: 75rem;
    padding-top: 4.5rem;
  }
</style>
