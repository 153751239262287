import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import Home from '@/views/Home.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER HOME:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Users:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/sms',
    name: 'SMS',
    component: () => import(/* webpackChunkName: "sms" */ '@/views/SMS.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SMS:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import(/* webpackChunkName: "reviews" */ '@/views/Reviews.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Reviews:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },

  {
    path: '/projects/:id',
    name: 'Project',
    component: () => import(/* webpackChunkName: "project" */ '@/views/Project.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER Project:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next();
      } else {
        console.log('NOT Authed, redirecting..');
        next('/signin');
      }
    },
  },
  // {
  //   path: "/action-items",
  //   name: "ActionItems",
  //   component: ActionItems,
  //   beforeEnter: (to, from, next) => {
  //     console.log("BEFORE ENTER ActionItems:", store.state.user);
  //     if (store.state.user != null) {
  //       next();
  //     } else {
  //       console.log("NOT Authed, redirecting..");
  //       next("/signin");
  //     }
  //   }
  // },

  {
    path: '/signin',
    name: 'UserSignIn',
    component: () => import(/* webpackChunkName: "signin" */ '@/views/UserSignIn.vue'),
    beforeEnter: (to, from, next) => {
      console.log('BEFORE ENTER SignIN:', store.state.user);
      if (store.state.user != null && store.state.user_roles.includes('admin')) {
        next('/');
      } else {
        next();
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from) => {
//   console.log(to, from);
//   // ...
//   // explicitly return false to cancel the navigation
//   // return false;
// });

export default router;
