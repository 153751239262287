<template>
  <div class="container bordered-container">
    <div class="row">
      <div class="col">
        <strong>ProjectId:</strong>
        &nbsp;
        <a :href="`/projects/${project_info.id}`" target="_blank">{{ project_info.id }}</a>
        &nbsp;
        <button class="copybtn" :data-clipboard-text="project_info.id">Copy ProjectID</button>
        <br />
        <!-- <strong>Category:</strong>
        {{ project_info.category }} - {{ project_info.category_description }}
        <br />

        <strong>Amount:</strong>
        {{ cents_in_euros(project_info.amount) }} - {{ project_info.amount_details }}
        <br /> -->

        <strong>Email:</strong>
        &nbsp;
        <UserEmail :email="project_info.email" />
        <br />

        <strong>Description:</strong>
        {{ project_info.description }}
        <br />

        <div v-if="project_info.requester_files && project_info.requester_files.length > 0">
          <strong>Files:</strong>
          <span v-html="requester_files_html"></span>
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { appAlertBuilder } from '@/helpers/app.js';
  import ClipboardJS from 'clipboard';
  import UserEmail from '@/components/UserEmail';

  const MAIN_ENDPOINT = process.env.VUE_APP_MAIN_ENDPOINT;

  export default {
    props: {
      projectId: String,
    },
    components: {
      UserEmail,
    },
    data() {
      return {
        project_id: null,
        project_info: {},
        requester_files_html: '',
      };
    },
    created() {
      var self = this;
      self.init();
    },
    methods: {
      cents_in_euros: function (x) {
        return parseInt(x) / 100 + ' €';
      },
      timeConverter(UNIX_timestamp) {
        let a = new Date(UNIX_timestamp * 1000);
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = a.getFullYear();
        let month = months[a.getMonth()];
        let date = a.getDate();
        let hour = a.getHours();
        let min = a.getMinutes();
        let sec = a.getSeconds();
        let time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
      },
      format_expected_delivery: function (value) {
        return ` <span class="redcolor">${value}</span>`;
      },
      format_delivered_at: function (delivered_at_timestamp) {
        if (delivered_at_timestamp == null) {
          return ' Unknown';
        }
        // return this.timeConverter(delivered_at_timestamp);

        let timestamp_now = +new Date() / 1000;
        let expiry_in_hours = ((timestamp_now - delivered_at_timestamp) / (60 * 60)).toFixed(2);

        return ` ${this.timeConverter(
          delivered_at_timestamp
        )} - <span class="redcolor">${expiry_in_hours} hours ago</span>`;
      },
      format_deadline: function (value) {
        return ` <span class="redcolor">${value}</span>`;
      },
      format_expiry: function (value) {
        let timestamp_now = +new Date() / 1000;
        let expiry_in_days = (value - timestamp_now) / (60 * 60 * 24);
        // if (expiry_in_days < 0){ expiry_in_days = "Never!"; }
        // if (value == null){ expiry_in_days = "Never!"; }

        return ` ${this.timeConverter(
          value
        )} - <span class="redcolor">In ${expiry_in_days} days</span> - <a href="https://www.epochconverter.com/" target="_blank">EpochConverter</a>`;
      },
      format_approved: function (value) {
        if (value) {
          return '<strong><font color="green">' + value + '</font></strong>';
        } else {
          return '<strong><font color="red">' + value + '</font></strong>';
        }
      },
      format_archived: function (value) {
        if (value) {
          return '<strong><font color="red">' + value + '</font></strong>';
        } else {
          return '<strong><font color="green">' + value + '</font></strong>';
        }
      },
      format_requester_files: function (value) {
        let output = ' ';
        if (value != null) {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            output += '<a href="' + element + '" target="_blank">Αρχείο' + String(index + 1) + '</a> - ';
          }
        }
        return output;
      },

      process_data: function () {
        var self = this;
        self.requester_files_html = self.format_requester_files(self.project_info.requester_files);
      },

      init: async function () {
        var self = this;
        self.project_id = self.$props.projectId;
        console.log('Initializing ', self.project_id);
        await self.fetch_project(self.project_id);
        console.log('Initialization completed for ', self.project_id);
      },

      fetch_project: async function (project_id) {
        var self = this;
        self.loading_in_progress = true;
        self.loading_status = null;

        await self.$store.dispatch('getValidToken');
        return fetch(`${MAIN_ENDPOINT}/projects/${project_id}`, {
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.loading_in_progress = false;
              self.loading_status = 'Request Submitted!';

              self.project_info = data.project;
              new ClipboardJS('.copybtn');
              self.process_data();
            }
          })
          .catch(function (error) {
            console.log(error);
            self.loading_in_progress = false;
            self.loading_status = appAlertBuilder(error, 'danger');
          });
      },
    },
  };
</script>

<style scoped>
  .bordered-container {
    border: 4px solid black;
    padding: 5px;
    border-radius: 4px;
  }
  .redcolor {
    color: red;
  }
  .greencolor {
    color: green;
  }
</style>
