<template>
  <!-- <a :href="'/users?user=' + email" target="_blank">{{ email }}</a> -->
  <span v-if="email">
    <a :href="'/users?user=' + email" target="_blank">{{ email }}</a>
    &nbsp;
    <button class="copybtn" :data-clipboard-text="email">Copy Email</button>
  </span>
</template>

<script>
  import ClipboardJS from 'clipboard';

  export default {
    props: ['email'],
    data() {
      return {};
    },
    mounted() {
      var self = this;
      new ClipboardJS('.copybtn');
    },
  };
</script>

<style></style>
