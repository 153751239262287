<template>
  <a :href="'/sms?phone=' + tel" target="_blank">{{ tel }}</a>
</template>

<script>
  export default {
    props: ['tel'],
    data() {
      return {};
    },
  };
</script>

<style></style>
