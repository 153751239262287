<template>
  <div>
    <button
      @click.prevent="refresh_projects_statuses"
      class="btn btn-primary btn-block"
      type="button"
      :disabled="refresh_in_progress || loading_in_progress">
      <span v-if="refresh_in_progress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      Refresh
    </button>
    &nbsp;
    <span v-html="refresh_status"></span>
    &nbsp;
    <div v-if="loading_in_progress" class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <span v-html="loading_status"></span>
    <span v-html="header"></span>
    <br />
    <br />
    <div class="col">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item" v-for="(status, index) in unique_statuses" :key="index">
          <h2 class="accordion-header" :id="'heading' + index">
            <button
              class="accordion-button"
              :class="{ collapsed: !shouldBeExpanded(status) }"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="'#collapse' + index"
              :aria-expanded="shouldBeExpanded(status)"
              :aria-controls="'collapse' + index">
              <strong>{{ status.name }} ({{ status.prIds ? status.prIds.length : 0 }})</strong>
            </button>
          </h2>
          <div
            :id="'collapse' + index"
            class="accordion-collapse collapse"
            :class="{ show: shouldBeExpanded(status) }"
            :aria-labelledby="'heading' + index">
            <div class="accordion-body">
              <span v-for="(prId, prIndex) in status.prIds" :key="prIndex">
                <!-- {{ prId }} -->
                <span v-if="!['paid', 'delivered'].includes(status?.name)">
                  <a :href="`/projects/${prId}`" target="_blank">{{ prId }}</a>
                  &nbsp;
                </span>
                <ProjectInfoV3 :projectId="prId" v-if="['paid', 'delivered'].includes(status?.name)" />
                <!-- <span v-if="prIndex != status.prIds.length - 1">&nbsp;-&nbsp;</span> -->
                <span v-if="prIndex != status.prIds.length - 1">
                  <br v-if="['paid', 'delivered'].includes(status?.name)" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectInfoV3 from '@/components/ProjectInfoV3';
  import { appAlertBuilder } from '@/helpers/app.js';
  var main_endpoint = process.env.VUE_APP_MAIN_ENDPOINT;

  export default {
    components: {
      ProjectInfoV3,
    },
    data() {
      return {
        loading_in_progress: null,
        loading_status: null,
        refresh_in_progress: null,
        refresh_status: null,

        unique_statuses: [
          { name: 'submitted', prIds: [] },
          { name: 'approved', prIds: [] },
          { name: 'has_offers', prIds: [] },
          { name: 'offer_selected', prIds: [] },
          { name: 'pending_payment', prIds: [] },
          { name: 'paid', prIds: [] },
          { name: 'pending_files_upload', prIds: [] },
          { name: 'pending_delivery', prIds: [] },
          { name: 'delivered', prIds: [] },
          { name: 'pending_provider_payment', prIds: [] },
          { name: 'completed', prIds: [] },
          { name: 'expired', prIds: [] },
          { name: 'cancelled', prIds: [] },
        ],
        header: null,

        projects_by_status: {},
      };
    },
    methods: {
      shouldBeExpanded(status) {
        return status.prIds && status.prIds.length > 0 && status.prIds.length < 100;
      },

      group_projects_statuses: function (data) {
        var self = this;
        let pr_statuses = new Set(Object.values(data));
        let statuses = Array.from(pr_statuses);
        console.log(pr_statuses, statuses);

        let groups_by_status = {};
        for (let index = 0; index < statuses.length; index++) {
          groups_by_status[statuses[index]] = [];
        }

        let pr_ids = Object.keys(data);
        for (let index = 0; index < pr_ids.length; index++) {
          let cur_proj = pr_ids[index];
          let cur_status = data[cur_proj];
          groups_by_status[cur_status].push(cur_proj);
        }

        console.log(groups_by_status);
        for (let index = 0; index < self.unique_statuses.length; index++) {
          self.unique_statuses[index].prIds = groups_by_status[self.unique_statuses[index].name] || [];
        }
      },

      format_header: function (timestamp, epoch_timestamp) {
        var self = this;
        let timestamp_now = +new Date() / 1000;
        let minutes_ago = (timestamp_now - epoch_timestamp) / 60;
        minutes_ago = minutes_ago.toFixed(2);
        if (minutes_ago < 0) {
          minutes_ago = 'Less than 1 minute!';
        }
        console.log(timestamp_now, epoch_timestamp, minutes_ago);

        self.header = '<strong>Last Update on: ' + timestamp + ' (' + minutes_ago + ' minutes ago)</strong>';
      },

      fetch_projects_statuses: async function () {
        var self = this;
        self.loading_in_progress = true;
        self.loading_status = null;

        await self.$store.dispatch('getValidToken');
        return fetch(`${main_endpoint}/projects-statuses`, {
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.loading_in_progress = false;

              self.group_projects_statuses(data.data);
              self.format_header(data.timestamp, data.epoch_timestamp);
            }
          })
          .catch(function (error) {
            console.log(error);
            self.loading_in_progress = false;
            self.loading_status = appAlertBuilder(error, 'danger');
          });
      },

      refresh_projects_statuses: async function () {
        var self = this;
        self.refresh_in_progress = true;
        self.refresh_status = null;

        await self.$store.dispatch('getValidToken');
        return fetch(`${main_endpoint}/projects-statuses`, {
          method: 'PUT',
          headers: {
            'Authorization': self.$store.state.token,
          },
        })
          .then(function (response) {
            return response.json();
          })
          .then(function (response) {
            console.log(response);
            let success = response.response.success;
            let message = response.response.message;
            let data = response.response.data;

            if (!success) {
              throw new Error(message);
            } else {
              self.refresh_in_progress = false;
              self.refresh_status = 'Refresh completed';
              // window.location = window.location;
              window.location.reload();
            }
          })
          .catch(function (error) {
            console.log(error);
            self.refresh_in_progress = false;
            self.refresh_status = appAlertBuilder(error, 'danger');
          });
      },
      init: async function () {
        var self = this;
        await self.fetch_projects_statuses();
      },
    },
    created() {
      var self = this;
      self.init();
    },
  };
</script>

<style></style>
