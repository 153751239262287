<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-md-4">
        <ProjectsStatusesV2 />
      </div>
      <div class="col-md-4">
        <ActionItemsV4 />
      </div>
      <div class="col-md-4">
        <!-- <ActionItems /> -->
        <!-- <ActionItemsV3 /> -->
        <ActionItemsV2 />
      </div>
    </div>
  </div>
</template>

<script>
  import ProjectsStatusesV2 from '@/components/ProjectsStatusesV2';
  // import ActionItems from '@/components/ActionItems';
  import ActionItemsV2 from '@/components/ActionItemsV2';
  // import ActionItemsV3 from '@/components/ActionItemsV3';
  import ActionItemsV4 from '@/components/ActionItemsV4';

  export default {
    components: {
      ProjectsStatusesV2,
      // ActionItems,
      ActionItemsV2,
      // ActionItemsV3,
      ActionItemsV4,
    },
  };
</script>
